<template>
  <div class="home">
      <!-- <div class="top_title_box">
        <div class="top_itle">新闻资讯</div>
      </div> -->
      <div class="readImg">
        <div class="readTitle">新闻资讯</div>
        <div class="readEnglish">News information</div>
      </div>
      <div class="headTop">
        <HeadTop />
      </div>
      <div class="wrap">
        <!-- <div class="now_title"><HeadTop /></div> -->
        <div class="wrap_content">
            <div class="wrap_content_left">
              <ul class="tile_biao_ul">
                <li class="news-title">新闻资讯</li>
                <li v-for="(item, index) in yearArray" @click="handleClick(index,item)" :key="index" :class="{active: currentIndex === index}">{{item.title}}</li>
              </ul>
            </div>
            <div class="wrap_content_right">
              <!-- <div class="cup_title">新闻资讯</div> -->
                <ul class="cup_ul" v-if="messList.length>0">
                  <li class="cup_li" v-for="(item, index) in messList" :key="index" @click="zxDetail(item)">
                    <div class="cup_li_left"><img :src="resBasePath+'/'+item.coverPath" alt=""></div>
                    <div class="cup_li_right">
                      <div class="cup_li_right_title">{{item.title}}</div>
                      <div class="cup_li_right_content">{{item.digest}}</div>
                      <div class="cup_li_right_time">{{format.format(item.createDate)}}</div>
                    </div>
                  </li>
                </ul>
                <div class="no-data-img" v-if="messList.length==0">
                  <img src="../../assets/img/noData.png" alt="" />
                  <div class="no-data-text"> 暂无内容</div>
                </div>
                <div class="page-right">
                  <a-config-provider>
                    <a-pagination
                      :default-current="1"
                      :page-size="pageSize"
                      @change="handleListChange"
                      :total=total />
                    </a-config-provider>
                </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import format from '@/utils/format.js'
import HeadTop from '@/components/HeadTop.vue'
export default {
  name: 'consulting',
  data () {
    return {
      format,
      resBasePath: this.$resBasePath,
      yearArray: [
        { title: '全部', dateNum: '' },
        { title: '2022', dateNum: 1640966400000 },
        { title: '2021', dateNum: 1622476800000 },
        { title: '2020', dateNum: 1590940800000 },
        { title: '2019', dateNum: 1559318400000 }
      ],
      currentIndex: 0,
      pageNum: 1,
      pageSize: 3,
      messList: [],
      createDate: '',
      total: 0
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    handleClick (index, item) {
      this.pageNum = 1
      this.pageSize = 3
      this.currentIndex = index
      this.createDate = item.dateNum
      this.getData()
    },
    getData () {
      const param = {
        access_token: '',
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        title: '',
        author: '',
        createDate: this.createDate
      }
      const _this = this
      axios({
        url: _this.$baseUrl + 'official/news/getNews',
        method: 'POST',
        data: param
      }).then(res => {
        if (res && res.data && res.data.success) {
          _this.messList = res.data.resultData.list || []
          _this.total = res.data.resultData.total
        }
      })
    },
    zxDetail (item) {
      sessionStorage.setItem('zxDetail', JSON.stringify(item))
      this.$router.push({ name: 'consultingDetail' })
    },
    handleListChange (current, pageSize) {
      this.pageNum = current
      this.pageSize = pageSize
      this.getData()
    }
  },
  components: {
    HeadTop
  }
}
</script>
<style lang="less">
ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.home{
  margin:0 auto;
  padding-bottom: 60px;
  .readImg{
      width:100%;
      height:260px;
      background: url('../../assets/img/zixun.png');
      background-size: 100% 100%;
      position: relative;
      .readTitle{
        text-align: center;
        color: #323232;
        line-height: 55px;
        letter-spacing: 4px;
        font-size: 36px;
        padding-top: 80px;
      }
      .readEnglish{
        color: #3D3D3D;
        line-height: 27px;
        letter-spacing: 2px;
        font-size: 20px;
        text-align: center;
      }
    }
    .headTop{
      width: 1140px;
      margin: 0 auto;
    }
    .wrap{
      width:1140px;
      margin:0 auto;
      .now_title{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #626675;
        margin-top:30px;
      }
      .wrap_content{
        width:1240px;
        display:flex;
        .wrap_content_left{
          width:200px;
          .tile_biao_ul{
            background:#FFFFFF;
            text-align: center;
            min-height: 630px;
            padding-top:20px;
            .news-title{
              background:@primary-color;
              color: #fff;
            }
            li{
              height:48px;
              line-height: 48px;
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #626675;
              cursor: pointer;
            }
            .active{
              color: @primary-color;
              font-weight: 400;
            }
            .li_all{
              display:flex;
              img{
                display:block;
                width:16px;
                height:16px;
                margin-top:20px;
                margin-right:10px;
                margin-left:50px;
              }
              span{
                display:block;
              }
            }
          }
        }
        .wrap_content_right{
          width:910px;
          background: #FFFFFF;
          margin-left:30px;
          padding:0 30px;
          box-sizing: border-box;
          .cup_title{
              font-size: 24px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #262626;
              padding-top:30px;
            }
            .cup_ul{
              min-height: 550px;
              .cup_li{
                height:196px;
                display: flex;
                padding:20px 0;
                box-sizing: border-box;
                cursor: pointer;
                .cup_li_left{
                  width: 240px;
                  height: 135px;
                  // background:@primary-color;
                  border-radius: 7px;
                  overflow: hidden;
                  img{
                    width: 100%;
                    height: 100%;
                  }
                }
                .cup_li_right{
                  width:870px;
                  padding-left: 30px;
                  box-sizing: border-box;
                  .cup_li_right_title{
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                    font-weight: 500;
                    color: #000000;
                    line-height: 28px;
                    overflow: hidden;
                    text-overflow: -o-ellipsis-lastline;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                  .cup_li_right_content {
                    // height: 85px;
                    font-size: 16px;
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    font-weight: 400;
                    color: #000;
                    margin-top: 15px;
                    overflow: hidden;
                    text-overflow: -o-ellipsis-lastline;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                  .cup_li_right_time{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #979797;
                    line-height: 28px;
                    margin-top:10px;
                  }
                }
              }
              .cup_li:not(:first-child){
                border-top:1px solid #EEE9DE;
              }

            }
            .no-data-img{
              text-align: center;
              padding-top: 100px;
            }
            .no-data-text {
              height: 28px;
              font-size: 20px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #BDB6B4;
              line-height: 28px;
              margin-bottom: 20px;
            }
            .page-right{
              height: 40px;
              line-height: 40px;
              text-align: center;
            }
        }
      }
    }

}
</style>
